import React, {Fragment, useEffect}  from 'react';
import StandardHeader from '../components/StandardHeader';
const Page404 = () => {
    let page = {
        title:'Page Can Not Be Found',
        image:'/images/girls-laughing-black-white.jpg',
        sub:"We are very sorry but the page you seem to be looking for can't be found, please use the navigation above to get back on track."
    }
    useEffect(()=>{
        document.title = "Page Can't Be Found";
        document.getElementById("meta_description").setAttribute("content", "Page can't Be Found");
    });
    return(
            <Fragment>
                <StandardHeader size='100' data={page} />
            </Fragment>
    )
}
export default Page404;