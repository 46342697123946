import React, {useContext, useEffect, useState}  from 'react';
import {getuser, getsubscription} from '../data/user';

import {BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import {Store} from '../stores/Store';
import Page404 from '../pages/Page404';
import Subscriptions from '../components/Subscriptions';
import Success from '../components/Success';
import AllFeeds from './AllFeeds';
import Feed from './Feed';
import Category from './Category';
import Loading from '../components/Loading';
import NotVerified from '../components/NotVerified';

const  Dashboard = (props) => {
    let context = useContext(Store);
    useEffect(()=>{
        if(!context.user){
            getuser(context).then((responce)=>{
                context.update({user:responce.data});
            });
        }
        if(!context.subscription){
            getsubscription(context).then((responce)=>{
                context.update({subscription:responce.data});
            });
        }
    }, [context]);
    ///weird thing that fixes issue with react router not re rendering the components correctly
    const { location } = props
    const [key, setKey] = useState(location.key)
    if(key !== location.key ){setTimeout(()=>setKey(location.key),0); return null}
    ///it kind of forces a refresh when the location key finally changes 

    return (context.subscription && context.user)?
        <BrowserRouter
            forceRefresh={true}
        >
        {(context.user && context.user.email_verified_at)?(
                    <Switch>
                        <Route path="/success" component={Success} />
                        <Route path="/subscribe" component={Subscriptions} />
                        <Route path='/' exact component={AllFeeds} />
                        <Route path='/feed/:feed_id/:cat_id' exact component={Category} />
                        <Route path='/feed/:feed_id' exact component={Feed} />
                        <Route path="*" component={Page404} />
                    </Switch>
                ):<NotVerified user={context.user} />
        }
        </BrowserRouter>:<Loading />
}
export default withRouter(Dashboard);