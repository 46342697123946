import React, {useState,useContext} from 'react';
import {register} from '../data/authentication';
import {Store} from '../stores/Store';
function checkemail(email){
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(String(email).toLowerCase()))
    {
      return true
    }else{
      return false
    }
  }
  const submitPassword = (pass1, pass2) =>{
    let pass = true;
    if(pass === true){pass = (pass1 === pass2)?true:"Passwords Don't Match";}
    if(pass === true){ pass = (pass1.length > 8)?true:"Password Not Long Enough"; }
    if(pass === true){ pass = (pass1.length < 101)?true:"Password Too Long";}
    return pass
}
const submitName = (name) =>{
    let pass = true;
    if(pass === true){ pass = (name.length > 1)?true:"Name Not Long Enough"; }
    if(pass === true){ pass = (name.length < 100)?true:"Name Too Long";}
    return pass
}
const userregister =  (name, email, password, passwordConfirm, context, setRegistered) =>{
    let pass = true;
    pass = submitName(name);
    pass = submitPassword(password, passwordConfirm);
    pass = checkemail(email);
    if(pass === true){
        register(name, email, password, passwordConfirm, context).then((response)=>{
            if(response.status === 401)
            {
                context.notify({
                    type:'error',
                    message:'You are not autherised to do this!'
                });
            }
            if(response.status === 422)
            {
                context.notify({
                    type:'error',
                    message:'There is an issue with the details you have entered - please amend and try again'
                });
            }
            if(response.status === 500)
            {
                context.notify({
                    type:'error',
                    message:'We are having issues with our servers - Please try later'
                });
            }
            if(response.status === 200)
            {
                context.notify({
                    type:'success',
                    message:'Registered - Please wait for your validation email and click the provided link'
                });
                setRegistered(true);
            }
        });
    }
}
const RegistrationForm = (props) =>{
    let context = useContext(Store);
    const [registered, setRegistered] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    return (
                <div className="container-fluid">
                    <div className="row">
                            <div className="col-4 hidden-xs hidden-sm regimage">
                            </div>
                            <div className="col-12 col-sm-12 col-md-8">
                            <div className='headerblock'>
                                <div className='row'>
                                    <div className='col-10 offset-1 col-md-8 offset-md-2'>
                                        {(!registered)?<div className='simpletransparantheader'>
                                            <h1>Register</h1>
                                            <p className="lead">Fill in the form below to register for dandelion meditation</p>
                                            <input name='name' type='text' value={name} onChange={(e)=>{setName(e.target.value)}} placeholder='Your Name' className='form-control' /><br/>
                                            <input name='email' type='text' value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='Your Email' className='form-control' /><br/>
                                            <input name='password' type='password' value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder='Your Password' className='form-control' /><br/>
                                            <input name='password_confirmation' type='password' value={passwordConfirm} onChange={(e)=>{setPasswordConfirm(e.target.value)}} placeholder='Confirm Your Password' className='form-control' /><br/>
                                            <button onClick={()=>{userregister(name, email, password, passwordConfirm, context, setRegistered)}} className='btn btn-success width100'>Register Me Now</button>
                                        </div>:<div className='simpletransparantheader'>
                                            <h1>Registration Complete</h1>
                                            <p className="lead" >Please check your email for your account activation link</p>
                                            <p className="lead" >The email will contain a button and a link. either click the button or copy and paste the link to verify your email address is valid.<br/> If you don't see it in your inbox please check your junk mail and mark it as not junk and then click the link provided.</p>
                                            <p className="subleadtitle" >Why do I need to do this? </p>
                                            <p className="sublead" >We take security and the protection of data seriously. This is why we take the minimum amount of data possible to enable you to use our services. For example, one way to protect our platform and the users is by only granting access to users who can prove their email address exists. We do this by sending a personal link to each registrant. When this link is clicked, we can confirm that it is a genuine email address.</p>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}
export default RegistrationForm;