import React, {useState} from 'react';
import {forgotpassword} from '../data/authentication';
import Loading from '../components/Loading';
const resetpassword = (email, setSent)=>{
    setSent(null);
    forgotpassword(email).then(()=>{
        setSent(true);
    });
}
const ForgotPasswordForm = (props) =>{
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);
    return (
            <section className={"RRD_header"}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className='headerblock'>
                                <div className='row'>
                                    <div className='col-10 offset-1 col-md-8 offset-md-2'>
                                        {(sent === false)
                                            ?
                                                <div className='simpletransparantheader'>
                                                    <h1 >Reset Password</h1>
                                                   <p className="lead" >Fill in the form below</p>
                                                    <p><span className='click' onClick={()=>props.setForgot(false)} >Back to log in</span></p>
                                                    <input name='email' type='text' value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='Your Email' className='form-control' /><br/>
                                                    <button onClick={()=>{resetpassword(email, setSent)}} className='btn btn-success width100'>Reset Password</button>
                                                </div>
                                            :(sent)
                                                ?
                                                    <div className='simpletransparantheader'>
                                                        <h1 >Password Reset</h1>
                                                        <p className="lead">Check your email for a reset link</p>
                                                        <p><span onClick={()=>props.setForgot(false)} style={{color:'#fff'}}>Back to log in</span></p>
                                                    </div>
                                                :<Loading size={100} unit='px' nologo={true} simple={true} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}
export default ForgotPasswordForm;