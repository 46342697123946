import React, {Component} from 'react';
import Routes from './Routes';
import "./styles/bootstrap.scss";
import "./styles/theme.scss";
import "./styles/custom.scss";
///store
import {Provider, Session} from "./stores/Store";
import { ToastProvider } from 'react-toast-notifications'

class App extends Component {
    constructor(props){
        super(props);
        this.state = Session ? this.setupSession():{
            update:this.update,
            reset:this.resetContext,
            token:null,
            billing:null,
            user:null,
            subscription:null,
            backgroundmusic:null
        }
        this.wrapper = React.createRef();
    }
    setupSession = () => {
        let Obj = JSON.parse(Session);
        Obj.update = this.update;
        Obj.reset = this.resetContext;
        return Obj;
    }
    update = (obj, callback) => {
        this.setState(obj,() => {
            sessionStorage.setItem('state', JSON.stringify(this.state));
            typeof callback === 'function' && callback();
        });
    }
    resetContext = () => {
        sessionStorage.clear('state');
        this.update({
            token:null,
            billing:null,
            seen:null,
            user:null,
            subscription:null
        });
    }
    render(){
        return (
            <ToastProvider ref={this.wrapper}>
                <Provider value={this.state}>
                    <Routes />
                </Provider>
            </ToastProvider>
        )
    }
}

export default App;
