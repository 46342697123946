import React, {Fragment, useState, useContext}  from 'react';
import {updateemail} from '../../data/user';
import {Store} from '../../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit
} from '@fortawesome/free-solid-svg-icons';

function checkemail(email, updateError){
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(String(email).toLowerCase()))
    {
      return true
    }else{
      return false
    }
  }
const submitemail = async(email, email2, context, setChangeEmail, props) => {
    if(checkemail(email) && (email === email2))
    {
        updateemail(email, context).then((responce)=>{
            setChangeEmail(false);
            context.reset();
            props.history.push("/");
            context.notify({
                type:'success',
                message:'You have updated your email, please check your email to re varify your email.'
            });
        }).catch((responce)=>{
            context.notify({
                type:'error',
                message:'There has been an issue with the details you entered, please check anf try again.'
            });
        });
    }else{
        context.notify({
            type:'error',
            message:'This does not seem to be a valid email'
        });
    }
}
const UpdateEmail = (props) => {
    let context = useContext(Store);
    const [changeEmail, setChangeEmail] = useState(false);
    const [email, setEmail] = useState(props.email);
    const [email2, setEmail2] = useState('');
    const alertuser = () =>{
        context.notify({
            type:'warning',
            message:'Changing your email will mean you need to re-varify your address before being able to log back on!'
        });
    }
    return (!changeEmail)
            ?
            <Fragment>
                <span>{email} </span>
                <FontAwesomeIcon className='click' onClick={()=>{alertuser(); setChangeEmail(true)}} icon={ faEdit } />
            </Fragment>
            :<Fragment>
                <input className='form-control' value={email} onChange={(e)=>{setEmail(e.target.value)}} type='text' placeholder='Email' />
                <input className='form-control' value={email2} onChange={(e)=>{setEmail2(e.target.value)}} type='text' placeholder='Repeat New Email' />
                <div style={{marginTop:'10px'}}>
                    <button onClick={()=>setChangeEmail(false)} className='btn btn-default'>Cancel</button>
                    <button onClick={()=>submitemail(email, email2, context, setChangeEmail, props)} className='btn btn-primary'>Update Email</button>
                </div>
            </Fragment>
}
export default UpdateEmail;