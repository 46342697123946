import React, { Fragment, useContext, useState, useEffect} from 'react';
import Breadcrumb from '../components/Breadcrumb';
import {getfeedcategory, getfeed} from '../data/cms';
import {Store} from '../stores/Store';
import Loading from '../components/Loading';
import List from '../components/Media/List';
const fetchfeedcategory = (id, cat_id, context, update) =>{
    return getfeedcategory(id, cat_id, context).then((responce)=>{
        (responce.success === true)&&update(responce.data);
    });
}
const fetchfeed = (id, context, update) =>{
    return getfeed(id, context).then((responce)=>{
        (responce.success === true)&&update(responce.data);
    });
}
const Category = (props) => {   
    useEffect(() => {
        document.title = 'Dandelion Meditation Feed Category';
        document.getElementById("meta_description").setAttribute("content", 'Category of a Feed of content in dandelion meditation app');
    });
    let context = useContext(Store);
    const [feeddata, setFeedData] = useState(null);
    const [contentdata, setContentData] = useState(null);
    const id = props.match.params.feed_id;
    const cat_id = props.match.params.cat_id;
    useEffect(()=>{
        (contentdata === null)&&fetchfeedcategory(id, cat_id, context, setContentData);
    }, [contentdata, context, id]);
    useEffect(()=>{
        if(feeddata === null){
            fetchfeed(id, context, setFeedData);
        }
    }, [feeddata, context, id]);
    return (feeddata !== null && contentdata !== null)?<Fragment>
            <Breadcrumb path={
                [
                    {
                        name:'Dashboard',
                        loc:'/'
                    },
                    {
                        name:feeddata.name,
                        loc:'/feed/'+id
                    },
                    {
                        name:contentdata.name,
                        loc:'/feed/'+id
                    }
                ]
            } />
            {
                (contentdata)
                    ?<List items={contentdata.containers} />
                    :<Loading />
            }
        </Fragment>:<Loading />
}
export default Category;