import React, {useState} from 'react';
import {ResendVerificationEmail} from '../data/user';
import {ucfirst} from '../helpers/text';

const NotVerified = (props) => {
    let [sent, setSent] = useState(false);
    const resend = () => {
        ResendVerificationEmail(props.user).then(()=>{
            setSent(true);
        });
    }
    return (
        <div className='row'>
            <div className='container space top'>
                <div className='col-12 col-sm-12  col-md-8 offset-md-2'>
                    <div className='card'>
                        <div className='card-header'>
                            Hi {ucfirst(props.user.name)} you have not verified your email address!
                        </div>
                        {(!sent)?<div className='card-body'>
                            <p>All emails on the platform need to be verified. Please check your inbox for our verification email click the link and logback in.</p> 
                            <p>Remember to check your junk folder and add us to your whitelist to recive future emails.</p>
                            <p><b>Not Recived it?</b> Its can sometimes take a few moments. if after 5 minutes you have not recived it please click the button below.</p>
                            <p><button onClick={resend} className='btn btn-success'>Resend link</button></p>
                        </div>:<div className='card-body'>
                            <p>Verification email sent! please give it a few moments </p>
                            <p>Its can sometimes take a few moments.</p>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NotVerified;