import React from 'react';
import Hero from './Hero';
import Doc from './Doc';
import Vid from './Vid';
import Audio from './Audio';
import AudioPlaylist from './AudioPlaylist';

const Media = (props) => {
    let type = props.data.items.find(A => A.slug === 'type');
    if(type && type.value === 'doc'){
      return  <Doc data={props.data} />
    }else if(type && type.value === 'hero'){
      return  <Hero data={props.data}/>
    }else if(type && type.value === 'video'){
      return  <Vid data={props.data} />
    }else if(type && type.value === 'audio'){
      return  <Audio data={props.data} />
    }else if(type && type.value === 'audioplaylist'){
      return  <AudioPlaylist data={props.data} />
    }else{
      return null
    }
};
export default Media