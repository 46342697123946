import React, {useState,useContext} from 'react';
import {login} from '../data/authentication';
import {Store} from '../stores/Store';
import { getseen } from '../data/cms';
import { getsubscription } from '../data/user';

const userlogin =  async (email, password, context) =>{
    
    login(email, password, context).then(async (response)=>{ 
        if(response.status === 401)
        {
            context.notify({
                type:'error',
                message:'There was an issue authenticating your username or password!'
            });
        }
        if(response.status === 500)
        {
            context.notify({
                type:'error',
                message:'We are having issues with our servers - Please try later'
            });
        }
        if(response.status === 200)
        {
            let seen = [];
            let sub = null;
            await getseen(context).then((res)=>{
                seen = res;
            });
            await getsubscription(context).then((responce)=>{
                sub = responce.data;
            });
            context.notify({
                type:'success',
                message:'Logging in',
                seen:seen,
                subscription:sub
            });
        }
    });
}
const LoginForm = (props) =>{
    let context = useContext(Store);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-4 hidden-xs hidden-sm loginimage">
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                        <div className='col-10 offset-1 col-md-8 offset-md-2'>
                            <div className='simpletransparantheader'>
                                <h1 >Log in to Dandelion Meditation</h1>
                                <p className="lead">Log in with the form below</p>
                                <p><span className='click' onClick={()=>props.setForgot(true)}>Forgot Password?</span></p>
                                <input name='email' type='text' value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='Your Email' className='form-control' /><br/>
                                <input name='password' type='password' value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder='Your Password' className='form-control' /><br/>
                                <button onClick={()=>{userlogin(email,password, context)}} className='btn btn-success width100'>Log in</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}
export default LoginForm;