import React, {useState, useEffect, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import {getsubscriptions} from '../data/subscriptions';
import {getCheckout} from '../data/stripe';
import { loadStripe } from '@stripe/stripe-js';
import {Store} from '../stores/Store';
import Loading from './Loading';
import NotVerified from '../components/NotVerified';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const Subscriptions = ()=>{
    let context = useContext(Store);
    const [subscriptions, setSubscriptions] = useState(null);
    const [monthy, setMonthly] = useState(null);
    const [annual, setAnnual] = useState(null);
    const [loadingCheckout, setLoadingCheckout] = useState(null);
    const handleClick = async (id) => {
        setLoadingCheckout(true);
        let checkout = {}; 
        await getCheckout(context, id).then((responce)=>{
            checkout = responce.data;
        });
        const stripe = await stripePromise;
        const session = checkout;
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        if (result.error) {
            setLoadingCheckout('error');
        }
      };
    useEffect(()=>{
        if(!subscriptions || subscriptions.length === 0){
            getsubscriptions().then((responce)=>{
                        setSubscriptions(responce.data);
                        responce.data.forEach(sub => {
                            if(sub.billing_cycle === 1 && sub.active === 1){
                                setMonthly(sub);
                            }
                            if(sub.billing_cycle === 12 && sub.active === 1){
                                setAnnual(sub);
                            }
                        });
            });
        }
    });
    return (context.user && context.user.email_verified_at)?(subscriptions && subscriptions.length > 0)?(<div className='row'>
            <div className='container space top'>
                <div className='col-12 col-sm-12  col-md-8 offset-md-2'>
                    <div className='card'>
                        <div className='card-header'>
                            Subscribe Today
                        </div>
                        <div className='card-body'>
                            {(!loadingCheckout)?<div className='subtableholder' >
                                <table className='subscriptiontable' >
                                    <thead>
                                        <tr>
                                            <th className='none'>
                                            </th>
                                            <th className='highlight'>Monthly<br/><button onClick={()=>handleClick(monthy.price_id)} className='btn btn-success'>Subscribe</button></th>
                                            <th className='highlight'>Annual<br/><button onClick={()=>handleClick(annual.price_id)} className='btn btn-success'>Subscribe</button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='highlight'>Price</td>
                                            <td>{(monthy)&&(monthy.currency === 'gbp')?'£':'$'}{(monthy)&&(monthy.price/100).toFixed(2)}</td>
                                            <td>{(annual)&&(annual.currency === 'gbp')?'£':'$'}{(annual)&&(annual.price/100).toFixed(2)}</td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Reduced Price</td>
                                            <td><FontAwesomeIcon icon={ faTimesCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Guided Meditations</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Reflective Meditations</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Meditations Techniques</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Sleep Meditations</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Sleep Music</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Articals</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Childrens Meditation</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Lifestyle Videos</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='highlight'>Healthy Living</td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                            <td><FontAwesomeIcon icon={ faCheckCircle } /></td>
                                        </tr>
                                        <tr>
                                            <td className='none'></td>
                                            <td><button onClick={()=>handleClick(monthy.price_id)} className='btn btn-success'>Subscribe</button></td>
                                            <td><button onClick={()=>handleClick(annual.price_id)} className='btn btn-success'>Subscribe</button></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>:(loadingCheckout === 'error')?<p>Error</p>:<Loading dark={true} simple={true} size='25' nologo={true} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>)
        :
            (!subscriptions)
            ?
                (<div className='container space top'>
                    <div className='col-12 col-sm-12  col-md-8 offset-md-2'>
                        <Loading simple={true} size='25' nologo={true} />
                    </div>
                </div>)
            :
                (
                    <p>No Subscription Options Found</p>
                ):<NotVerified user={context.user} />
}
export default Subscriptions;