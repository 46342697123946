import React from 'react';
import Media from './';
import NoMedia from './NoMedia';
const List = (props) => {
    let ordered = props.items.sort((a, b)=>{
        let order_a = a.items.find(A => A.slug === 'order');
        let order_b = b.items.find(B => B.slug === 'order');
        order_a = (order_a && order_a.value)?order_a.value:100;
        order_b = (order_b && order_b.value)?order_b.value:100;
        return (order_a > order_b)?1:-1
      });
    return (
        <div className='row paddinghorizontal'>
            {
                (ordered.length > 0)?ordered.map((item, i)=>{
                    return (
                        <div className='col-12 col-md-4 col-sm-6 space'>
                            <Media key={i} data={item} />
                        </div>
                    )
                }):<NoMedia space={true} />
            }
        </div>
        
        
    );
}
export default List;