import React, {Fragment, useState, useContext}  from 'react';
import {updatepassword} from '../../data/authentication';
import {Store} from '../../stores/Store';

const submitPassword = (pass1, pass2, old, context, setChangePassword) =>{
    let pass = true;
    if(pass === true){pass = (pass1 === pass2)?true:"Passwords Don't Match";}
    if(pass === true){ pass = (pass1.length > 8)?true:"Password Not Long Enough"; }
    if(pass === true){ pass = (pass1.length < 101)?true:"Password Too Long";}
    if(pass === true){
       updatepassword(pass1, pass2, old, context).then((responce)=>{
            setChangePassword(false);
            context.notify({
                type:'success',
                message:'You have updated your password'
            });
        }).catch((responce)=>{
            context.notify({
                type:'error',
                message:'There was a problem updating your password'
            });
        });
    }else{
        context.notify({
            type:'error',
            message:'Please recheck the details you entered, and try again: '+pass
        });
    }
}
const UpdatePassword = (props) => {
    let context = useContext(Store);
    const [changePassword, setChangePassword] = useState(false);
    const [pass1, setPass1] = useState('');
    const [pass2, setPass2] = useState('');
    const [old, setOld] = useState('');
    return (!changePassword)
            ?<button onClick={()=>setChangePassword(true)} className='btn btn-default'>Change Password</button>
            :<Fragment>
                <input className='form-control' value={old} onChange={(e)=>{setOld(e.target.value)}} type='password' placeholder='Current Password' />
                <input className='form-control' value={pass1} onChange={(e)=>{setPass1(e.target.value)}} type='password' placeholder='Password' />
                <input className='form-control' value={pass2} onChange={(e)=>{setPass2(e.target.value)}} type='password' placeholder='Repeat New Password' />
                <div style={{marginTop:'10px'}}>
                    <button onClick={()=>setChangePassword(false)} className='btn btn-default'>Cancel</button>
                    <button onClick={()=>submitPassword(pass1, pass2, old, context, setChangePassword)} className='btn btn-primary'>Update Password</button>
                </div>
            </Fragment>
}
export default UpdatePassword;