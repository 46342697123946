import React, {useEffect}  from 'react';
import RegistrationForm from '../components/RegistrationForm';

const Register = (props) => {
    useEffect(() => {
        document.getElementById("meta_description").setAttribute("content", 'Dandelion Meditation Registration');
    }, []);
    return <RegistrationForm color='white' />

}
export default Register;