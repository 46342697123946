import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
const Loading = (props) => {
    return (
        <div className={((props.simple)?'simpleloading':'loading')} style={{height:((props.size)?props.size:'100')+((props.unit)?props.unit:"vh")}}>
            <div className='container pos-vertical-center text-center'>
                <div className="row">
                    {(!props.nologo)&&((props.dark === true)?<div className="col-6 offset-3">
                        <span>
                            
                            <img className="logo logoloadingspace" alt="logo" style={{width:'100%'}} src="/images/logo.png" />
                        </span>
                    </div>:<div className="col-6 offset-3">
                        <span>
                            <img className="logo logoloadingspace" alt="logo" style={{width:'100%'}} src="/images/logo-light.png" />
                        </span>
                    </div>)}
                </div>
                <div className='row'>
                    <div className='col-12' style={{color:(props.dark !== true)?'#fff':'#717070'}}>
                        <FontAwesomeIcon style={{color:(props.dark !== true)?'#fff':'#717070'}} icon={ faSpinner } spin />
                        <div style={{fontSize:(props.fontSize)?props.fontSize:'26px'}}><br/>{(props.name)?props.name:'Loading'}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Loading;