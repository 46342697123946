import React, {useState, useContext, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {Store} from '../stores/Store';
import {getfeed, getfeedcategory} from '../data/cms';
import Media from '../components/Media/';
import LoadingMedia from './Media/LoadingMedia';
import NoMedia from './Media/NoMedia';
// Import Swiper React components
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
SwiperCore.use([Navigation, Pagination, A11y]);


const FeedSlider = (props) => {
    let context = useContext(Store);
    const [data, setData] = useState(null);
    const [content, setContent] = useState(null);
    const fetchFeed = async () => {
        return await getfeed(props.id, context);
    }
    const fetchCat = async () => {
        return await getfeedcategory(props.id, props.cat_id, context);
    }
    useEffect(()=>{
        let mounted = true;
        if(!props.cat_id){
            fetchFeed().then((responce)=>{
                if(mounted === true)
                {
                    setData(responce.data)
                }
            });
        }else{
            fetchCat().then((responce)=>{
                if(mounted === true)
                {
                    setData(responce.data)
                }
            });
        }
            return function cleanup() {
            mounted = false;
        }
    }, [props.id]);
    useEffect(()=>{
        if(data && data.containers){
            let ordered = data.containers.filter((item)=>(props.cat_id)?item.category.length > 0:data.categories.filter(cat=>item.category.includes(cat.id)).length === 0).sort((a, b)=>{
                let order_a = a.items.find(A => A.slug === 'order');
                let order_b = b.items.find(B => B.slug === 'order');
                order_a = (order_a && order_a.value)?order_a.value:100;
                order_b = (order_b && order_b.value)?order_b.value:100;
                return (order_a > order_b)?1:-1
              });
            setContent(
                ordered
            );
        };
    }, [data]);
    return (
        <div className='slider'>
            <div className='row ' >
                <div className='col-12 paddinghorizontal slidertitle'>
                    <h3>
                        {(!props.cat_id)?<NavLink className="slider-link" to={"/feed/"+props.id}>{props.title}</NavLink>:<NavLink className="slider-link" to={"/feed/"+props.id+"/"+props.cat_id}>{props.title}</NavLink>}
                    </h3>
                </div>
                <div className='col-12'>
                    {(content)?
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        navigation
                        breakpoints={{
                            1024: {
                            slidesPerView: 4,
                            },
                            768: {
                            slidesPerView: 3,
                            },
                        }}
                    >
                      {(content.length > 0)?content.map((item, i)=>{
                            return <SwiperSlide key={i} index={i}><Media  data={item} /></SwiperSlide>
                            
                        }):<SwiperSlide index={0}><NoMedia /></SwiperSlide>}
                  </Swiper>
                    :<LoadingMedia name={props.title} />}
                </div>
            </div>
        </div>
    )
}
export default FeedSlider;