import React from 'react'
import {NavLink} from 'react-router-dom';

const StandardHeader = (props) =>{
    return (
        <section className={"RRD_header"} style={{background:"url("+props.data.image+")", minHeight:((props.size)?props.size:'100')+((props.unit)?props.unit:"vh")}}>
                <div className="container pos-vertical-center">
                    <div className="row">
                        <div className="col-12">
                            <div className='headerblock'>
                                <div className='row'>
                                    <div className='col-10 offset-1 col-md-8 offset-md-2'>
                                        <div className='simpletransparantheader'>
                                            {(props.data.title)&&(<h1 style={{color:(props.color?props.color:'#fff')}} dangerouslySetInnerHTML={{__html:decodeURIComponent(props.data.title)}}></h1>)}
                                            {(props.data.sub)&&(<p style={{color:(props.color?props.color:'#fff')}} className="lead" dangerouslySetInnerHTML={{__html:decodeURIComponent(props.data.sub)}}></p>)}
                                            {(props.data.buttonLoc)&&(!props.data.external)?
                                                <NavLink className={"btn "+((props.buttonclass)?props.buttonclass:"btn-success")} exact to={props.data.buttonLoc?props.data.buttonLoc:'/'} activeClassName="active">
                                                    {props.data.buttonText?props.data.buttonText:'Start'}
                                                </NavLink>:<a className={"btn "+((props.buttonclass)?props.buttonclass:"btn-success")} href={props.data.buttonLoc?props.data.buttonLoc:'/'} >
                                                    {props.data.buttonText?props.data.buttonText:'Start'}
                                                </a>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {(props.element)&&props.element}
                            </div>
                        </div>
                    </div>
                </div>
        </section>
    );
}
export default StandardHeader;