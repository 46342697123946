import axios from 'axios';
const serverloc = process.env.REACT_APP_API;


export const checkresponce = (res, context) => {
    if(res.success === false && res.message === 'expired')
    {
        context.reset();
        window.location = '/';
    }else if(res.success === false && res.message === 'suspended')
    {
        context.reset();
        window.location = '/';
    }else if(res.success === false && res.message === 'unauthorized')
    {
        context.reset();
        window.location = '/';
    }
    return res.data;
}
export const getfeed = async(id, context) => {
    try {
        const response = await axios.get(serverloc+'/api/geturi/getfeed?product_id=ejPYCQXhUwjnEZ3hHhXO&feed_id='+id,{
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return checkresponce(response);
    } catch (error) {
        return error.response;
    }
}
export const getcontainer = async(id, context) =>{
    try {
        const response = await axios.get(serverloc+'/api/geturi/getcontainer?product_id=ejPYCQXhUwjnEZ3hHhXO&container_id='+id,{
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return checkresponce(response);;
    } catch (error) {
        return error.response;
    }
}
export const getseen = async(context) =>{
    try {
        const response = await axios.get(serverloc+'/api/seen',{
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return checkresponce(response);
    } catch (error) {
        return error.response;
    }
}
export const getfeedcategories = async(id, context) => {
    try {
        const response = await axios.get(serverloc+'/api/geturi/getfeedcategories?product_id=ejPYCQXhUwjnEZ3hHhXO&feed_id='+id,{
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return checkresponce(response);
    } catch (error) {
        return error.response;
    }
}
export const getfeedcategory = async(id, cat, context) => {
    try {
        const response = await axios.get(serverloc+'/api/geturi/getfeedcategory?product_id=ejPYCQXhUwjnEZ3hHhXO&feed_id='+id+'&cat_id='+cat,{
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return checkresponce(response);
    } catch (error) {
        return error.response;
    }
}
export const getBackgroundMusic = async (context, feed_id) => {
    try {
        const response = await axios.get(serverloc+'/api/geturi/getfeed?product_id=ejPYCQXhUwjnEZ3hHhXO&feed_id='+feed_id,{
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return checkresponce(response);
    } catch (error) {
        return error.response;
    }
}