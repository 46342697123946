import React, {Fragment, useState, useContext}  from 'react';
import {updatename} from '../../data/user';
import {Store} from '../../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit
} from '@fortawesome/free-solid-svg-icons';

const submitName = (name, context, setChangeName) =>{
    let pass = true;
    if(pass === true){ pass = (name.length > 1)?true:"Name Not Long Enough"; }
    if(pass === true){ pass = (name.length < 100)?true:"Name Too Long";}
    if(pass === true){
       updatename(name, context).then((responce)=>{
            setChangeName(false);
            context.notify({
                type:'success',
                message:'You have updated your name.'
            });
        }).catch((responce)=>{
            context.notify({
                type:'error',
                message:'There has been an issue with the details you entered, please check anf try again.'
            });
        });
    }else{
        context.notify({
            type:'error',
            message:'Please recheck the details you entered, and try again: '+pass
        });
    }
}
const UpdateName = (props) => {
    let context = useContext(Store);
    const [changeName, setChangeName] = useState(false);
    const [name, setName] = useState(props.name);
    return (!changeName)
            ?
            <Fragment>
                <span>{name} </span>
                <FontAwesomeIcon className='click' onClick={()=>setChangeName(true)} icon={ faEdit } />
            </Fragment>
            :<Fragment>
                <input className='form-control' value={name} onChange={(e)=>{setName(e.target.value)}} type='text' placeholder='Your Name' />
                <div style={{marginTop:'10px'}}>
                    <button onClick={()=>setChangeName(false)} className='btn btn-default'>Cancel</button>
                    <button onClick={()=>submitName(name, context, setChangeName)} className='btn btn-primary'>Update Name</button>
                </div>
            </Fragment>
}
export default UpdateName;