import React, {Fragment, useState, useEffect, useContext} from 'react';
import ReactDOM from 'react-dom';
import MediaHolder from '../MediaHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFileAudio, faPlay, faStop, faStar, faLock, faEye
} from '@fortawesome/free-solid-svg-icons';
import {getBackgroundMusic, getcontainer} from '../../data/cms';
import {Store} from '../../stores/Store';
import LoadingCover from '../LoadingCover';
export const ViewAudio = (props) => {
    let context = useContext(Store);
    const [playing, setPlaying] = useState(false);
    const [backgroundSelected, setBackgroundSelected] = useState(null);
    const [backgroundMusic, setBackgroundMusic] = useState(null);
    const [selectedBackgroundAudioId, setSelectedBackgroundAudioId] = useState(null);
    const [logged, setLogged] = useState(false);
    let id = props.data.id;
    
    let title = props.data.items.find(i => i.slug === 'title');
    let subtext = props.data.items.find(i => i.slug === 'subtext');
    let track = props.data.items.find(i => i.slug === 'audioLoc');
    let backgroundOn = props.data.items.find(i => i.slug === 'backgroundOn');
    let defaultbackground = props.data.items.find(i => i.slug === 'DefaultBackgroundAudio');
    let ids = (defaultbackground.value && defaultbackground.value.length > 0)?defaultbackground.value.split("!link!"):[];
    let audioele = React.createRef();
    let backgroundaudioele = React.createRef();
    let autorepeat =  props.data.items.find(i => i.slug === 'autorepeat');
    useEffect(() => {
        if(backgroundOn.value === 'true'){
            let feed_id = (ids[0])?ids[0]:'svTEyrivbcgI8UJQnJau';
            getBackgroundMusic(context, feed_id).then((responce)=>{
                if(responce.success === true){
                    setBackgroundMusic(responce.data.containers.filter((item)=>{
                        let type = item.items.find(i => i.slug === 'type');
                        return type.value === 'background'
                    }));
                }
            });
        }
      }, []);
      useEffect(()=>{
        (!logged)&&getcontainer(id, context);
        (!logged)&&setLogged(true);
      }, [id]);
    useEffect(() => {
        if(backgroundMusic && ids[1] && ids[1].length > 0){
            let i = 0;
            for(let track of backgroundMusic){
                if(ids[1] === track.id){
                    setbackgroundaudio(i);
                    setSelectedBackgroundAudioId(i);
                }
                i++;
            }
        }
    }, [backgroundMusic]);
    useEffect(() => {
        if(playing){
            backgroundaudioele.play();
        }
    }, [backgroundSelected]);
    const setbackgroundaudio = (track) => {
        if(track !== null)
        {
            let background = backgroundMusic[track].items.find(i => i.slug === 'track');
            let volume = backgroundMusic[track].items.find(i => i.slug === 'volume');
            setSelectedBackgroundAudioId(track);
            setBackgroundSelected(background.value);
            backgroundaudioele.volume = parseFloat(volume.value);
        }else{
            setBackgroundSelected(null);
            backgroundaudioele.volume = 0;
        }
    }
    return (backgroundOn.value === 'true' && backgroundMusic === null)?<LoadingCover/>:(
        <div
                style={
                    {
                        display:'flex',
                        flex:1,
                        justifyContent:'start',
                        flexDirection:'column'
                    }
                }
            >
            <div style={
                {display:'flex'}
            }>
                <div
                    style={
                        {
                            background:'rgba(0,0,0,0.5)',
                            width:'100%',
                            padding:'20px',
                            color:'#fff',
                            borderRadius:20
                        }
                    }
                >
                    {(title)&&<h3 style={
                        {
                            textAlign:'center',
                            color:'#fff'
                        }
                    }>{title.value}</h3>}
                    {(subtext)&&<p>{subtext.value}</p>}
                </div>
            </div>
            <div
                style={
                    {
                        display:'flex',
                        flex:1,
                        justifyContent:'space-evenly',
                        flexDirection:'column'
                    }
                }
            >
                <div style={
                    {display:'flex'}
                }>
                    <div className={'click playbutton '+((playing)?'breath':'')} onClick={() => {
        if(!playing){
            audioele.play();
            (backgroundOn.value === 'true')&&backgroundaudioele.play();
        }
        if(playing){
            audioele.pause();
            (backgroundOn.value === 'true')&&backgroundaudioele.pause();
        }
        audioele.onplaying = () => {
            setPlaying(true);
        };
        audioele.onpause = () => {
            setPlaying(false);
        };
        audioele.onended = () => {
            if(autorepeat && autorepeat.value === 'true'){
                
            }else{
                setPlaying(false);
                (backgroundOn.value === 'true')&&setTimeout(()=>backgroundaudioele.pause(), 5000);
            }
        };
    }}>
                        {(!playing)?<FontAwesomeIcon icon={ faPlay} />:<FontAwesomeIcon icon={ faStop } />}
                    </div>
                </div>
                {(backgroundOn.value === 'true')&&<div style={
                    {display:'flex'}
                }>
                    <select style={{background:'rgba(0,0,0,0.5)', border:'none', color:'#fff', borderRadius:20, padding:'0px 20px'}} className='form-control' value={selectedBackgroundAudioId} onChange={(e)=>{setbackgroundaudio(e.target.value)}}>
                        <option value={null}>No Background Audio</option>
                        {
                            backgroundMusic.map((item, i)=>{
                                let name = item.items.find(i => i.slug === 'name');
                                return <option key={i} value={i}>{(name && name.value)?name.value:'unknown'}</option>
                            })
                        }
                    </select>
                </div>}
            </div>
            <audio ref={ref => audioele = ref} loop={(autorepeat && autorepeat.value === 'true')?true:false} controlsList="nodownload" src={track.value}>
                Your browser does not support the audio element.
            </audio>
           {(backgroundOn.value === 'true')&&<audio ref={ref => backgroundaudioele = ref} loop={true} controlsList="nodownload" src={backgroundSelected}>
                Your browser does not support the audio element.
            </audio>}
        </div>
    )
}
const Audio = (props) =>{
    const context = useContext(Store);
    const popuploc = document.getElementById('popupholder');
    let image = props.data.items.find(D => D.slug === 'listing_image');
    let title = props.data.items.find(B => B.slug === 'title');
    let subtext = props.data.items.find(E => E.slug === 'subtext');
    let free = props.data.items.find(F => F.slug === 'free');
    let docbackground = props.data.items.find(A => A.slug === 'background_image');
    const [toggle, setToggle] = useState(false);
    
    const ViewMedia = (toggle)=>{
        if(!context.subscription || !context.subscription.active){
            if(free && free.value === 'true'){
                setToggle(toggle);
            }else{
                window.location.href = '/subscribe';
            }
        }else{
            setToggle(toggle);
        }
    }
    return (
        <Fragment>
            <div 
                onClick={
                    ()=>{
                        ViewMedia(!toggle);
                    }} 
                className='coverimage hero click' 
                style={{background:"#a8a8a8 url("+((image)?image.value:'')+")", height:'200px', borderRadius:'15px', overflow:'hidden'}}
            >
                <span  title='Audio' style={{
                    float:'right',
                    fontSize:'24px',
                    margin:'12px'
                }}><FontAwesomeIcon icon={ faFileAudio } /></span>
                <div style={{position:'absolute', bottom:0, padding:'10px', background:'rgba(0,0,0,0.7)', width:'100%'}} className="legend">
                    <span style={{
                        display:'block',
                        fontSize:'22px'
                    }}>{title.value}</span>
                    <span className=''>{subtext.value}</span>
                </div>
            </div>
            {(toggle)&&ReactDOM.createPortal(<MediaHolder background={docbackground.value} title={title.value} toggle={()=>{ViewMedia(!toggle)}} content={<ViewAudio data={props.data} />} />, popuploc)}
        </Fragment>
    );
}
export default Audio;