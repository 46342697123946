import React, {Fragment, useEffect, useContext}  from 'react';
import Breadcrumb from '../components/Breadcrumb';
import UserDetails from '../components/Users/UserDetails';
import {getuser} from '../data/user';
import {Store} from '../stores/Store';
const User = () => {
    let context = useContext(Store);
    useEffect(() => {
        document.title = 'User';
        document.getElementById("meta_description").setAttribute("content", 'Your Dandelion User Page');
      }, []);
      useEffect(()=>{
            getuser(context).then((responce)=>{
                context.update({user:responce.data});
            });

    }, [context]);
    return (
            <Fragment>
                <Breadcrumb path={
                [
                    {
                        name:'Dashboard',
                        loc:'/'
                    },{
                        name:'User',
                        loc:'/user'
                    }
                ]
            } />
                <UserDetails />
            </Fragment>
    );
}
export default User;