import React, {useEffect, useContext} from  'react';
import { Store } from '../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';

const MediaHolder = (props) => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return function cleanup() {
            document.body.style.overflowY = 'scroll';
        };
    }, []);

    return (
        <div
            onClick={(e)=>{
                e.stopPropagation();
                props.toggle();
            }} 
            className={'mediaHolderbackground'}>
            <div onClick={(e)=>{
                e.stopPropagation();
            }}  
            
            className={'mediaHolder'} >
                
                <div className='mediaHolder-head'>
                    {props.title?props.title:null}
                    <span className='close' onClick={()=>{
                        props.toggle();
                    }}><FontAwesomeIcon icon={ faTimes } /></span>
                </div>
                <div className='mediaHolder-body'
                    style={
                        {
                            display:'flex',
                            background:"#fff url("+((props.background)?props.background:'')+")",
                            backgroundRepeat:'no-repeat',
                            backgroundSize:'cover',
                            backgroundPosition:'center'
                        }
                    }
                >
                    {props.content}
                </div>
            </div>
        </div>
    );
}
export default MediaHolder;