import React, {Fragment,useEffect, useState, useContext} from 'react';
import ReactDOM from 'react-dom';
import MediaHolder from '../MediaHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {decodeHTMLEntities} from '../../helpers/decode';
import {
    faFileVideo, faStar, faLock, faEye
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '../../stores/Store';
import {getcontainer} from '../../data/cms';

export const ViewVid = (props) => {
    const context = useContext(Store);
    let title = props.data.items.find(i => i.slug === 'title');
    let subtext = props.data.items.find(i => i.slug === 'subtext');
    let body = props.data.items.find(E => E.slug === 'body');
    let vidLoc = props.data.items.find(B => B.slug === 'vidLoc');
    let poster = props.data.items.find(C => C.slug === 'poster');
    const [logged, setLogged] = useState(false);
    let id = props.data.id;
    
    useEffect(()=>{
        (!logged)&&getcontainer(id, context);
        (!logged)&&setLogged(true);
    }, [id]);
    return (
        <div
                style={
                    {
                        flex:1,
                        justifyContent:'flex-start',
                        flexDirection:'column'
                    }
                }
            >
            {(!body)&&<div
                style={
                    {
                        flex:1,
                        marginBottom:'5%'
                    }
                }
            ><div
                style={
                    {
                        background:'rgba(0,0,0,0.5)',
                        width:'100%',
                        padding:'20px',
                        color:'#fff',
                        borderRadius:20
                    }
                }
            >
                {(title)&&<h3 style={{textAlign:'center', color:'#fff'}} >{title.value}</h3>}
                {(subtext)&&<p>{subtext.value}</p>}
            </div></div>}
            <div
                style={
                    {
                        flex:1,
                        marginBottom:'5%'
                    }
                }
            >
                {(vidLoc)&&(
                    <video style={{
                        width:'100%',
                        height:'auto',
                        objectFit:'cover',
                        borderRadius:20
                    }} controls controlsList="nodownload" poster={(poster)&&poster.value} >
                        <source src={vidLoc.value} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>
            {(body)&&<div
                style={
                    {
                        flex:1,
                        background:'rgba(0,0,0,0.5)',
                        width:'100%',
                        padding:'20px',
                        color:'#fff'
                    }
                }
            >
                {(title)&&<h3 style={{textAlign:'center', color:'#fff'}} >{title.value}</h3>}
                {(subtext)&&<p>{subtext.value}</p>}
                {(body)&&<div style={{textAlign:'center'}} dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(body.value) }}></div>}
            </div>}
        </div>
    )
}
const Vid = (props) =>{
    const context = useContext(Store);
    const popuploc = document.getElementById('popupholder');
    let docbackground = props.data.items.find(A => A.slug === 'background_image');
    let image = props.data.items.find(D => D.slug === 'listing_image');
    let title = props.data.items.find(B => B.slug === 'title');
    let subtext = props.data.items.find(E => E.slug === 'subtext');
    let free = props.data.items.find(F => F.slug === 'free');

    const [toggle, setToggle] = useState(false);
    const ViewMedia = (toggle)=>{
        setToggle(toggle);
        /*if(!context.subscription || !context.subscription.active){
            if(free && free.value === 'true'){
                setToggle(toggle);
            }else{
                window.location.href = '/subscribe';
            }
        }else{
            
        }*/
    }
    return (
        <Fragment>
            <div 
                onClick={
                    ()=>{
                        ViewMedia(!toggle);
                    }} 
                className='coverimage hero click' 
                style={{background:"#a8a8a8 url("+((image)?image.value:'')+")", height:'200px', borderRadius:'15px', overflow:'hidden'}}
            >
                <span  title='Video'  style={{
                    float:'right',
                    fontSize:'24px',
                    margin:'12px'
                }}><FontAwesomeIcon icon={ faFileVideo } /></span>
                <div style={{position:'absolute', bottom:0, padding:'10px', background:'rgba(0,0,0,0.7)', width:'100%'}} className="legend">
                
                    <span style={{
                        display:'block',
                        fontSize:'22px'
                    }}>{title.value}</span>
                    <span>{subtext.value}</span>
                </div>
            </div>
            {(toggle)&&ReactDOM.createPortal(<MediaHolder title={title.value} background={(docbackground)?docbackground.value:false} toggle={()=>{ViewMedia(!toggle)}} content={<ViewVid data={props.data} />} />, popuploc)}
        </Fragment>
    );
}
export default Vid;