import React, {useContext, useEffect} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useToasts } from 'react-toast-notifications'
import Nav from './components/Nav';
import Footer from './components/Footer';
import Login from './pages/Login';
import Register from './pages/Register';
import Loading from './components/Loading';
import Page404 from './pages/Page404';
import Dashboard from './pages/Dashboard';
import User from './pages/User';
import {Store} from './stores/Store';
import {getseen} from './data/cms';
const Routes = (props) => {
    let context = useContext(Store);
    const { addToast } = useToasts()
    useEffect(()=>{
        (!context.notify)&&context.update(
            {
                notify:(notifyobj)=>addToast(notifyobj.message, { appearance: notifyobj.type, autoDismiss: true })
            }
        );
    }, [context, addToast]);
    return (
        <BrowserRouter>
            <Nav />
            <div className={(context.token)?'main-container':'main-container-login'} style={{background:"url('/images/background1.png')"}}>
                {(!context.token)
                ?
                    (
                        <Switch>
                            <Route path='/' exact component={Login} />
                            <Route path='/register' exact component={Register} />
                            <Route path="*" component={Page404} />
                        </Switch>
                    )
                :
                    (
                        <Switch>
                            <Route path='/user' exact component={User} />
                            <Route path='/logout' exact component={(props)=>{
                                context.reset();
                                props.history.push("/");
                                return <Loading/>
                            }} />
                            <Route path="*" exact component={()=><Dashboard/>} />
                        </Switch>
                    )
                }
            </div>
            <div id="popupholder"></div>
        <Footer />
        </BrowserRouter>
    );
}
export default Routes