import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
const NoMedia = (props) =>{
    return (
        <div className={(props.space)&&'morespace'} style={{
            position:'relative', 
            backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.05), rgba(0,0,0,0))', 
            height:'200px', 
            width:'96%', 
            padding:'40px', 
            marginLeft:'2%', 
            marginRight:'2%'
            }}
        >
            <div style={{border:'1px solid rgba(255, 255, 255, 0.3)', padding:'20px 10px', textAlign:'center'}}>
            <FontAwesomeIcon  style={{fontSize:'40px'}} icon={ faExclamationCircle } />
                <p style={{fontSize:'20px'}}>No Content<br/>
                <span style={{fontSize:'14px'}}>We are working hard to add more soon</span></p>
            </div>
        </div>
    );
}
export default NoMedia;