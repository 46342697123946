import React from  'react';
import Loading from './Loading';
const LoadingOverlay = (props) => {
    return (
        <div className={'mediaHolderbackground'}>
            <div className='mediaHolder'>
                <Loading />
            </div>
        </div>
    );
}
export default LoadingOverlay;