import React from 'react'
import {Link} from 'react-router-dom';

const Breadcrumb = (props) =>{
    return (
                <div className="row">
                    <div className="col-12 breadcrumbheader paddinghorizontal">
                        <div className='breadcrumb'>
                            {props.path.map((item, i)=>{
                                return <span key={i}><Link to={item.loc}>{item.name}</Link> {(props.path.length > i + 1)&&' > '}</span>
                            })}
                        </div>
                    </div>
                </div>
    );
}
export default Breadcrumb;