import axios from 'axios';
const auth = process.env.REACT_APP_AUTH;
export const getBillingPortal = async(context) => {
    try {
        const response = await axios.get(auth + '/user/billing', {
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getCheckout = async(context, id) => {
    try {
        const response = await axios.post(auth + '/user/checkout', {price_id:id}, {
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}