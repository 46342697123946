import React, { useContext, useEffect, useState}  from 'react';
import Loading from '../Loading';
import {getuser} from '../../data/user';
import {Store} from '../../stores/Store';
import {getBillingPortal} from '../../data/stripe';
import {getsubscription} from '../../data/user';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import UpdatePassword from './UpdatePassword';
import UpdateName from './UpdateName';
import UpdateEmail from './UpdateEmail';
const humantime = (date) =>{
    let utc = moment.utc(date).toDate();
    let local = moment(utc).local().format('YYYY-MM-DD');
    let human = moment(utc).fromNow();
    return local+' ('+human+')';
}
const UserDetails = (props) => {
    let context = useContext(Store);
    const [user, setUser] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [gotobilling, setGotoBilling] = useState(null);
    
    const getbilling = () =>{
        setGotoBilling(true);
        getBillingPortal(context).then((responce)=>{
            if(responce.data.customer){
                
                window.location.href = responce.data.url;

            }
        }).catch(()=>{
            setGotoBilling('error');
        });
    }
    useEffect(()=>{
        getuser(context).then((responce)=>{
            setUser(responce.data);
        });
        
        getsubscription(context).then((responce)=>{
            if(((responce.data.stripe_customer_id && responce.data.stripe_customer_id.length > 1) || (responce.data.apple_original_order_id && responce.data.apple_original_order_id.length > 1)) && responce.data.active){
                setSubscription(responce.data);
            }
        });
    }, [context]);
    return (user)?(user.id )?(
        <div className='row'>
            <div className='col-12 col-md-6 offset-md-3'>
                <div className='card morespace'>
                    <div className='card-header'>
                        User Details and Billing
                    </div>
                    <div className='card-body'>
                        <table className='usertable'>
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td><UpdateName name={user.name} /></td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td><UpdateEmail email={user.email}/></td>
                                </tr>
                                <tr>
                                    <td>Account Created</td>
                                    <td>{humantime(user.created_at)}</td>
                                </tr>
                                <tr>
                                    <td>Last Log In</td>
                                    <td>{humantime(user.last_loggedin)}</td>
                                </tr>
                                <tr>
                                    <td>Password</td>
                                    <td>
                                        <UpdatePassword/>
                                    </td>
                                </tr>
                                {(subscription && subscription.stripe_customer_id.length > 0)&&<tr>
                                    <td>Billing</td>
                                    <td>
                                        <button 
                                            className={(!gotobilling)?'btn btn-success':'btn btn-disabled'}
                                            onClick={(!gotobilling)?getbilling:()=>window.alert('There has been an issue geting your billing info, please refresh the page or try again later.')}
                                        >
                                            {(!gotobilling)
                                                ?(subscription)?'Subscription and Billing':'Historic Billing' 
                                                :(gotobilling)?'Loading':'Error - try later' 
                                            }
                                        </button>
                                    </td>
                                </tr>}
                                {(subscription && subscription.apple_original_order_id.length > 0)&&<tr>
                                    <td>Billing</td>
                                    <td>
                                        This subscription is managed in the Apple App Store 
                                    </td>
                                </tr>}
                                {(!user.team)?(user.email_verified_at)&&(!subscription)&&<tr>
                                    <td>Choose Subscription</td>
                                    <td><NavLink className="btn btn-success" exact to={"/"}>
                                            New Subscription
                                        </NavLink></td>
                                </tr>:<tr><td>Choose Subscription</td><td>You have a subscription through a team</td></tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    ):(
        <p>Error Loading User Details</p>
    ):<Loading simple={true} />
}
export default UserDetails