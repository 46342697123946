import axios from 'axios';
const auth = process.env.REACT_APP_AUTH;
export const getuser = async (context) => {
    try {
        const response = await axios.get(auth + '/user', {
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const updatename = async (name, context) => {
    try {
        const response = await axios.post(auth + '/user/update/name',{
            name:name
        }, {
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const updateemail = async (email, context) => {
    try {
        const response = await axios.post(auth + '/user/update/email',{
            email:email
        }, {
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const getsubscription = async (context) => {
    try {
        const response = await axios.get(auth + '/user/subscription', {
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const ResendVerificationEmail = async (user) => {
    let returnitem = {};
    return axios.get(auth+'/user/resendverification/'+user.identifier)
    .then((response) => {
        returnitem.success = true;
        returnitem.data = response.data
        return returnitem
    }).catch(
        (error)=>{
            returnitem.error = error;
            return returnitem
        }
    );
}