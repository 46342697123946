import React, {useState, useContext, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import ReactDOM from 'react-dom';
import PopupMedia from './Popupmedia';
import DailyThought from './DailyThought';
import { Store } from '../../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faStar } from '@fortawesome/free-solid-svg-icons';
const navigate = (id, cat, view, hist) =>{
    if(view === 'cat'){
        hist.push("/feed/"+id+'/'+cat);
    }
    if(view === 'feed'){
        hist.push("/feed/"+id);
    }
}

const Hero = (props) =>{
    const context = useContext(Store);
    let title = props.data.items.find(B => B.slug === 'title');
    let subtext = props.data.items.find(C => C.slug === 'subtext');
    let image = props.data.items.find(D => D.slug === 'image');
    let id = props.data.items.find(E => E.slug === 'id');
    let cat = props.data.items.find(H => H.slug === 'cat');
    let view = props.data.items.find(F => F.slug === 'view');
    let template = props.data.items.find(G => G.slug === 'herotype');
    let free = props.data.items.find(F => F.slug === 'free');
    let color = props.data.items.find(I => I.slug === 'color');
    const popuploc = document.getElementById('popupholder');
    const [toggle, setToggle] = useState(false);
    let styles = {
        box:{
            background:"#a8a8a8 url("+((image)?image.value:'')+")",
            height:'200px',
            borderRadius:'15px',
        },
        title:{
            display:'block',
            margin:(((!context.subscription || !context.subscription.active)) && (template.value === 'lefttop' || template.value === 'righttop'))?17:0,
            padding:0,
            fontSize:24,
            lineHeight:'32px',
            fontWeight:'bold',
            color:(color && color.value === 'dark')?'#333':'#fff',
            textAlign:(template.value === 'lefttop' || template.value === 'leftbottom')?'right':'left',
        },
        subtext:{
            display:'block',
            marginTop:2,
            marginBottom:2,
            color:(color && color.value === 'dark')?'#333':'#fff',
            textAlign:(template.value === 'lefttop' || template.value === 'leftbottom')?'right':'left',
            lineHeight:'14px',
            fontSize:13,
            fontWeight:400,
        },
        textshadow:{
            textShadow:(color && color.value === 'dark')?'2px 2px rgba(255,255,255, 0.5)':'2px 2px rgba(0,0,0, 0.5)'
        },
        textshadowsub:{
            textShadow:(color && color.value === 'dark')?'1px 1px rgba(255,255,255, 0.5)':'2px 2px rgba(0,0,0, 0.5)'
        },
          content:{
            position:'absolute',
            width:'90%',
            padding:10,
            right:(template.value === 'lefttop' || template.value === 'leftbottom')?'0px':'default',
            bottom:(template.value === 'lefttop' || template.value === 'righttop')?'0px':'default',
        },
    }
    
    const ViewMedia = (toggle)=>{
        setToggle(toggle);
        /*if(!context.subscription || !context.subscription.active){
            if(free && free.value === 'true'){
                setToggle(toggle);
            }else{
                window.location.href = '/subscribe';
            }
        }else{
            
        }*/
    }
    return (
        <div onClick={()=>{
                if(!['audio', 'video', 'doc', 'dailythought'].includes(view.value)){
                    navigate(id.value,cat.value,view.value, props.history)
                }else{
                    ViewMedia(!toggle);
                }
            }
        } className='coverimage hero click' style={styles.box}>
            {(toggle)&&ReactDOM.createPortal((['audio', 'video', 'doc'].includes(view.value))
                ?<PopupMedia id={id.value} view={view.value} toggle={()=>{ViewMedia(!toggle)}} />
                :<DailyThought id={id.value} toggle={()=>{ViewMedia(!toggle)}}/>, popuploc)}
            <div style={{...styles.content}}>
                <span style={{...styles.title, ...styles.textshadow}}>{title.value}</span>
                <span style={{...styles.subtext, ...styles.textshadowsub}}>{subtext.value}</span>
            </div>
        </div>
    )
}
export default withRouter(Hero);