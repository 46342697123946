import React, { Fragment, useContext, useState, useEffect} from 'react';
import Breadcrumb from '../components/Breadcrumb';
import {getfeed} from '../data/cms';
import {Store} from '../stores/Store';
import FeedSlider from '../components/FeedSlider';
import Loading from '../components/Loading';
import List from '../components/Media/List';


const Feed = (props) => {
    let context = useContext(Store);
    const [feeddata, setFeedData] = useState(null);
    const id = props.match.params.feed_id;
    useEffect(() => {
        document.title = (feeddata && feeddata.name)?feeddata.name:'Dandelion Meditation Feed';
        document.getElementById("meta_description").setAttribute("content", (feeddata && feeddata.name)?feeddata.name+' Feed in dandelion meditation app':'Feed of content in dandelion meditation app');
    }, [feeddata]);
    useEffect(()=>{
        let mounted = true
        if(feeddata === null){
            getfeed(id, context).then((responce)=>{
                if(responce.success === true && mounted === true){
                    setFeedData(responce.data);
                }
            });
        }
        return function cleanup() {
            mounted = false;
        }
    }, [feeddata, context, id]);
    return (feeddata !== null)?<Fragment>
            <Breadcrumb path={
                [
                    {
                        name:'Dashboard',
                        loc:'/'
                    },
                    {
                        name:feeddata.name,
                        loc:'/feed/'+id
                    }
                ]
            } />
            {
                (feeddata.categories)
                    ?(feeddata.categories.length > 0)
                        ?feeddata.categories.map((item, i)=>{
                            return (!item.sub_cat || item.sub_cat.length === 0)&&<FeedSlider key={i} title={item.name} id={id} cat_id={item.id} />
                        })
                        :<List items={feeddata.containers} />
                    :<Loading />
            }
        </Fragment>:<Loading />
}
export default Feed;