import React from 'react';
const Footer = () =>{
    return (
        <footer className="footer-3 text-center-xs space--xs">
            <div className="container">
                <div className="col-12">
                    <img alt="Dandelion Meditation Logo" className="logo" src="/images/logo.png" />
                    <ul className="list-inline list--hover">
                        <li className="list-inline-item">
                            <a href='https://www.raspberryrippledesign.co.uk'>
                                <span>Copyright Raspberry Ripple Design LTD</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}
export default Footer;