import React, { Fragment, useEffect} from 'react';
import FeedSlider from '../components/FeedSlider';
import Breadcrumb from '../components/Breadcrumb';
const AllFeeds= (props) => {
    useEffect(() => {
        document.title = 'Dandelion Meditation Dashboard';
        document.getElementById("meta_description").setAttribute("content", 'All Feeds and Content');
    });
    return (
        <Fragment>
            <Breadcrumb path={
                [
                    {
                        name:'Dashboard',
                        loc:'/'
                    }
                ]
            } />
            <FeedSlider title='Featured' id='ErRzs15Wi1g8P1JPG83T' />
            <FeedSlider title='Meditation' id='Dz0vfvmQb7LqCrhmWROf' />
            <FeedSlider title='Children' id='xleTwFen5WZZRCErqSmD' />
            <FeedSlider title='Sleep' id='SQAGjriDzyiM9TvzR2Vc' />
            <FeedSlider title='Discover' id='4f0F3ucAEWb1urk18uCI' />
        </Fragment>
    )
}
export default AllFeeds;