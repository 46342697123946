import React, {useState, useEffect, useContext} from 'react';
import MediaHolder from '../MediaHolder';
import {getfeed, getcontainer} from '../../data/cms';
import {Store} from '../../stores/Store';
import LoadingCover from '../LoadingCover';
const DailyThought = (props) =>{
    let context = useContext(Store);
    
    const [objectData, setObjectData] = useState(false);
    const [logged, setLogged] = useState(false);
    useEffect(()=>{
        let mounted = true;
        getfeed(props.id, context).then((responce)=>{
            if(mounted === true){
                setObjectData(responce.data.containers);
            }
        });
        return function cleanup() {
            mounted = false;
        }
    }, []);
    
    let name = null;
    let text = null;
    let audio = null;
    if(objectData)
    {
        /// get day of the year 
        var now = new Date();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);
        ////
        //// work out what thought should be displayed based on the idea of cycling all of the available thoughts no matter if short or long
        let cycle =  (day/objectData.length) - Math.floor(day/objectData.length);
        let cyclefraction = (1/objectData.length);
        let currentday = Math.floor(cycle/cyclefraction);

        let thought = (objectData && objectData.length > 0)?objectData[currentday]:null
        let id = thought.id;
        (!logged)&&getcontainer(id, context);
        (!logged)&&setLogged(true);
        for(let i = 0; thought.items.length > i; i++){
            name = (thought.items[i].slug === 'title')?thought.items[i].value:name;
            text = (thought.items[i].slug === 'thoughttext')?thought.items[i].value:text;
            audio = (thought.items[i].slug === 'audio')?thought.items[i].value:audio;
        }
    }

    return <MediaHolder 
                background={'/images/co.jpg'} 
                title={'Daily Thought'} 
                toggle={props.toggle} 
                content={(objectData)?<div style={{
                    flex:1,
                }}><div style={{
                    position:'relative',
                    top:'20%',
                    padding:'20px',
                    background:'rgba(0,0,0,0.2)',
                    borderRadius:'20px'
                }}>
                    <h1 style={{color:'#fff'}}>{name}</h1>
                    <p style={{color:'#fff'}}>{text}</p>
                    {(audio)&&<audio controlsList="nodownload" src={audio} controls={true}>
                        Your browser does not support the audio element.
                    </audio>}
                </div></div>:<LoadingCover />} 
            />;
}
export default DailyThought;