import React, {useContext, useEffect, useState, Fragment} from 'react';
import ReactDOM from 'react-dom';
import MediaHolder from '../MediaHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {decodeHTMLEntities} from '../../helpers/decode';
import {
    faFileAlt, faStar, faLock, faEye
} from '@fortawesome/free-solid-svg-icons';
import {getcontainer} from '../../data/cms';
import { Store } from '../../stores/Store';

export const ViewDoc = (props) => {
    const context = useContext(Store);
    let title = props.data.items.find(i => i.slug === 'title');
    let subtext = props.data.items.find(i => i.slug === 'subtext');
    let body = props.data.items.find(E => E.slug === 'body');
    let main_image = props.data.items.find(A => A.slug === 'main_image');
    const [logged, setLogged] = useState(false);
    let id = props.data.id;
    useEffect(()=>{
        (!logged)&&getcontainer(id, context);
        (!logged)&&setLogged(true);
    }, [id]);
    return (
        <div style={{flex:1,}}>
            {(main_image)&&<img alt={title+' '+subtext} src={main_image.value} style={{width:'100%'}} />}
            {(title)&&<h3 style={{textAlign:'center'}} >{title.value}</h3>}
            {(subtext)&&<p>{subtext.value}</p>}
            {(body)&&<div style={{textAlign:'center', marginBottom:'100px', padding:'30px'}} dangerouslySetInnerHTML={{ __html: decodeHTMLEntities(body.value) }}></div>}
        </div>
    )
}
const Doc = (props) =>{
    
    const popuploc = document.getElementById('popupholder');
    const context = useContext(Store);
    let background = props.data.items.find(A => A.slug === 'listing_background');
    let image = props.data.items.find(D => D.slug === 'listing_image');
    let title = props.data.items.find(B => B.slug === 'title');
    let subtext = props.data.items.find(E => E.slug === 'subtext');
    let free = props.data.items.find(F => F.slug === 'free');
    
    const [toggle, setToggle] = useState(false);
    const ViewMedia = (toggle)=>{
        setToggle(toggle);
        /*if(!context.subscription || !context.subscription.active){
            if(free && free.value === 'true'){
                setToggle(toggle);
            }else{
                window.location.href = '/subscribe';
            }
        }else{
            
        }*/
    }
    return (
        <div
                style={
                    {
                        display:'flex',
                        flex:1,
                        justifyContent:'space-between',
                        flexDirection:'column'
                    }
                }
            >
            <div 
                onClick={
                    ()=>{
                        ViewMedia(!toggle);
                    }} 
                className='coverimage hero click' 
                style={{background:"#a8a8a8 url("+((image)?image.value:(background)?background.value:'')+")", height:'200px', borderRadius:'15px', overflow:'hidden'}}
            >
                <span  title='Document'  style={{
                    float:'right',
                    fontSize:'24px',
                    margin:'12px'
                }}><FontAwesomeIcon icon={ faFileAlt } /></span>
                <div style={{position:'absolute', bottom:0, padding:'10px', background:'rgba(0,0,0,0.7)', width:'100%'}} className="legend">
                    <span style={{
                        display:'block',
                        fontSize:'22px'
                    }}>{title.value}</span>
                    <span>{subtext.value}</span>
                </div>
            </div>
            {(toggle)&&ReactDOM.createPortal(<MediaHolder title={title.value} toggle={()=>{ViewMedia(!toggle)}} content={<ViewDoc data={props.data} />} />, popuploc)}
        </div>
    );
}
export default Doc;