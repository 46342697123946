import React, {useState, useEffect, useContext, Fragment} from 'react';
import {ViewAudio} from './Audio';
import {ViewVid} from './Vid';
import {ViewDoc} from './Doc';
import MediaHolder from '../MediaHolder';
import {getcontainer} from '../../data/cms';
import {Store} from '../../stores/Store';
import LoadingOverlay from '../LoadingOverlay';
const PopupMedia = (props) =>{
    let context = useContext(Store);
    const [objectData, setObjectData] = useState(false);
    let title;
    let docbackground;
    if(objectData){
        title = objectData.items.find(B => B.slug === 'title');
        docbackground = objectData.items.find(A => A.slug === 'background_image');
    }
    useEffect(()=>{
        if(['audio', 'video', 'doc'].includes(props.view)){
            /// get the data
            getcontainer(props.id, context).then((responce)=>{
                setObjectData(responce.data);
            });
            /// set data
        }
    }, []);
    return (objectData)?<MediaHolder 
                background={docbackground.value} 
                title={title.value} 
                toggle={props.toggle} 
                content={<Fragment>
                            {(props.view === 'audio')&&<ViewAudio data={objectData} />}
                            {(props.view === 'video')&&<ViewVid data={objectData} />}
                            {(props.view === 'doc')&&<ViewDoc data={objectData} />}
                        </Fragment>} 
            />:<LoadingOverlay />;
}
export default PopupMedia;