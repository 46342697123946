import axios from 'axios';
const auth = process.env.REACT_APP_AUTH;
export const login = async (email, password, context) => {
    try {
        const response = await axios.post(auth + '/user/login', {
            email: email,
            password: password
        });
        await context.update({ token: response.data.token });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const register = async (name, email, password, passwordConfirm, context) => {
    try {
        const response = await axios.post(auth + '/user/register', {
            name: name,
            email: email,
            source:'web',
            password: password,
            password_confirmation: passwordConfirm
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const forgotpassword = async (email, password, context) => {
    try {
        const response = await axios.post(auth + '/user/forgotpassword', {
            email: email
        });
        return response;
    } catch (error) {
        return error.response;
    }
}
export const updatepassword = async (pass1, pass2, old, context) => {
    try {
        const response = await axios.post(auth + '/user/update/password', {
            oldpassword: old,
            password: pass1,
            password_confirmation: pass2
        },{
            headers: {
                Authorization: 'Bearer ' + context.token //the token is a variable which holds the token
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
}