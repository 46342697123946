import React, {useState, useContext, Fragment} from 'react';
import {NavLink, Link, withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars, faSignOutAlt, faUserAlt, faCircle, faSpa, faCloudMoon, faBurn, faChild, faCalendar
} from '@fortawesome/free-solid-svg-icons';
import PopupMedia from './Media/Popupmedia';
import DailyThought from './Media/DailyThought';
import {Store} from '../stores/Store';
import ReactDOM from 'react-dom';
const Links = (props) =>{
    let context = useContext(Store);
    let website = process.env.REACT_APP_WEBSITE;
    const popuploc = document.getElementById('popupholder');
    const [toggle, setToggle] = useState(false);
    return (props.context.token)?(
        <Fragment>
            <ul className="menu-horizontal text-left" style={{paddingTop:'10px'}}>
                <li>
                    <NavLink className="nav-link" exact to={"/"} activeClassName="active">
                        <FontAwesomeIcon icon={ faCircle } /><br/>
                        <span>Today</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" exact to={"/feed/Dz0vfvmQb7LqCrhmWROf"} activeClassName="active">
                        <FontAwesomeIcon icon={ faSpa } /><br/>
                        <span>Meditation</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" exact to={"/feed/xleTwFen5WZZRCErqSmD"} activeClassName="active">
                        <FontAwesomeIcon icon={ faChild } /><br/>
                        <span>Children</span>
                    </NavLink>
                </li>
                <li>
                    <span className="nav-link click" onClick={
                        ()=>{
                            setToggle(!toggle);
                            ReactDOM.createPortal(<DailyThought id={'qJMSltSB75g1kxXNNOBK'} toggle={()=>{setToggle(!toggle)}}/>, popuploc)
                        }}>
                        {(toggle)&&ReactDOM.createPortal(<DailyThought id={'qJMSltSB75g1kxXNNOBK'} toggle={()=>{setToggle(!toggle)}}/>, popuploc)}
                        <FontAwesomeIcon icon={ faCalendar } /><br/>
                        <span>Daily Thought</span>
                    </span>
                </li>
                <li>
                    <NavLink className="nav-link" exact to={"/feed/SQAGjriDzyiM9TvzR2Vc"} activeClassName="active">
                        <FontAwesomeIcon icon={ faCloudMoon } /><br/>
                        <span>Sleep</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" exact to={"/feed/4f0F3ucAEWb1urk18uCI"} activeClassName="active">
                        <FontAwesomeIcon icon={ faBurn } /><br/>
                        <span>Discover</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" exact to={"/logout"} activeClassName="active">
                        <FontAwesomeIcon icon={ faSignOutAlt } /><br/>
                        <span>Sign out</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-link" exact to={"/user"} activeClassName="active">
                        <FontAwesomeIcon icon={ faUserAlt } /><br/>
                        <span>user</span>
                    </NavLink>
                </li>
            </ul>
        </Fragment>
    ):(
        <Fragment>
            <ul className="menu-horizontal text-left" style={{paddingTop:'10px'}}>
                <li>
                    <a className="nav-link out" href={website}>
                        <span>Home</span>
                    </a>
                </li>
                <li>
                    <NavLink className="nav-link out" exact to={"/register"} activeClassName="active">
                        <span>Register</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className="nav-link out" exact to={"/"} activeClassName="active">
                        <span>Log in</span>
                    </NavLink>
                </li>
            </ul>
        </Fragment>
    );
}
const Nav = () =>{
    const [open, setOpen] = useState(false);
    let context = useContext(Store);
    return (
        <div className="nav-container ">
            <div className="bar bar--sm visible-sm visible-xs">
                <div className="container">
                    <div className="row">
                        <div className="col-9">
                            <span className='click textleft'>
                                <Link to={"/"}>
                                    <img className="logo" alt="logo" src="/images/logo.png" />
                                </Link>
                            </span>
                        </div>
                        <div className="col-3 text-right">
                            {(!open)?<span onClick={()=>{
                                setOpen(!open)
                            }} className="click hamburger-toggle">
                                <FontAwesomeIcon icon={ faBars } />
                            </span>:<span onClick={()=>{
                                setOpen(!open)
                            }} className="click hamburger-toggle">
                                <FontAwesomeIcon icon={ faBars } transform={{ rotate: 90 }}/>
                            </span>}
                        </div>
                    </div>
                    {(open === true)&&(
                    <div className="mobilenav">
                            <Links context={context} />
                    </div>)}
                </div>
            </div>
            <nav id="menu2" className={"hidden-sm  hidden-xs"}>
                    <div className="row">
                        <div className="col-lg-3  paddinghorizontalright hidden-sm hidden-xs order-lg-2">
                            <div className='click textright'>
                                <Link to={"/"}>
                                    <img style={{height:'50px', marginTop:'10px'}} className="logo" alt="logo" src={"/images/logo.png"} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-9 paddinghorizontalleft order-lg-1">
                            <div className="bar__module">
                                <Links context={context} />
                            </div>
                        </div>
                    </div>
            </nav>
        </div>
    );
}
export default withRouter(Nav);