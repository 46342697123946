import React, {useEffect, useState}  from 'react';
import LoginForm from '../components/LoginForm';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
const Login = (props) => {
    const [forgot, setForgot] = useState(false);
    useEffect(() => {
        document.getElementById("meta_description").setAttribute("content", 'Dandelion Meditation Registration');
    }, []);
    return (!forgot)
        ?<LoginForm size={100} unit='%' color='white' setForgot={setForgot} />
        :<ForgotPasswordForm size={550} unit='px' color='white' setForgot={setForgot}  />
                
}
export default Login;