import React, {useEffect, useContext} from 'react';

import {getsubscription} from '../data/user';
import {Store} from '../stores/Store';
import Loading from './Loading';

const Subscriptions = (props)=>{
    let context = useContext(Store);
    useEffect(()=>{
        getsubscription(context).then((responce)=>{
            context.update({subscription:responce.data});
            props.history.push("/");
        }).catch(
            (error)=>{
                context.notify({
                    type:'error',
                    message:'There was an error with your subscription - Please re try to log in'
                });
            }
        );
    }, [context]);
    return <Loading name={'Checking subscription and starting your session!'} />
}
export default Subscriptions;